import React from "react";
import UnderConstructionPage from "./Page Gif/under-construction";

function App() {
  return (
    <div className="App">
      <UnderConstructionPage />
    </div>
  );
}

export default App;
