import React from "react";
import "./pageGif.css";

const UnderConstructionPage = () => {
  return (
    <>
      <h1 className="title">
        Web page under construction / Página da Web em construção{" "}
      </h1>
      <div className="page-one">
        <a href="https://www.gifsanimados.org/cat-engranajes-1215.htm">
          <img
            src="https://www.gifsanimados.org/data/media/1215/engranaje-imagen-animada-0006.gif"
            border="0"
            alt="engranaje-imagen-animada-0006"
          />
        </a>
        '
      </div>
      <footer>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://cyantechsolutions.mdbgo.io/"
        >
          <p className="footer">
            Made with ❤ for <strong>Cyan Tech Solutions</strong>
          </p>
        </a>
      </footer>
    </>
  );
};
export default UnderConstructionPage;
